import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import PolicyPageDoc from "@chetwoodfinancial/pws-components/PolicyPageDoc";

import SEO from "../components/SEO";
import Layout from "../components/Layout";

const PolicyPage = ({ pageContext }) => {
    const { pageHeading, policyId, policyShortName } = pageContext;

    return (
        <Layout title={pageHeading}>
            <Layout.Content>
                {/* Build fails without checking window here - Some kind of gatsby SSR bug */}
                {typeof window !== "undefined" && (
                    <Box sx={{ mt: 4 }}>
                        <PolicyPageDoc
                            policyLongName={pageHeading}
                            {...{
                                policyId,
                                policyShortName
                            }}
                        />
                    </Box>
                )}
            </Layout.Content>
        </Layout>
    );
};

PolicyPage.propTypes = {
    pageContext: PropTypes.shape({
        pageHeading: PropTypes.string.isRequired,
        pageMeta: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        }),
        policyId: PropTypes.string.isRequired,
        policyShortName: PropTypes.string.isRequired
    }).isRequired
};

// eslint-disable-next-line react/prop-types
export const Head = ({ pageContext: { pageMeta } }) => <SEO {...pageMeta} />;

export default PolicyPage;
